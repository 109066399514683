'use client';

import React from 'react';
import Cart from 'components/commercetools-ui/organisms/cart';
import type { CartProps } from 'components/commercetools-ui/organisms/cart/types';
import type { TasticProps } from '../types';

const CartTastic = ({ data }: TasticProps<CartProps>) => {
    return <Cart {...data} />;
};

export default CartTastic;
